import React from "react";
import "./Login.css";
import Logo from "../../Assets/OnlyLogo.png";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import { login, token } from "../../Hooks/UserHooks";
import { showToast } from "../../Utils/showToast";

const Login = () => {
  let navigate = useNavigate();
  const [user, setuser] = useState({email:"",password:""});

  useEffect(() => {
    if (token) {
      navigate("/dashboard/buy");
    }
  }, [navigate]);

  function handleChange(evt) {
    const value = evt.target.value;
    setuser({
      ...user,
      [evt.target.name]: value,
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if(user.email.length<6||user.password.length<4){
        showToast("password and email must be more than 6 digits","error")
      }else{
      await axios
        .post(
          "https://backend.aim-estate.com/api/users/login",
          { email: user.email, password: user.password },
          { headers: { "Content-Type": "application/json" } }
        )
        .then((res) => {
          if (res.status === 201) {
            login(res.data.token);
            window.location.href="/dashboard/buy"
          } else {
            showToast("invalid Credentials !", "error");
          }
        });
      }
    } catch (error) {
      showToast(error.response.data, "error");
    }
  }

  return (
    <>
      <div className="loginParent">
        <form onSubmit={handleSubmit} className="loginForm">
          <img className="loginImg" src={Logo} alt="Aim logo" />

          <div className="inputWrapper">
            <label className="loginLabel">Email</label>
            <input
              onChange={handleChange}
              name="email"
              placeholder="John"
              type={"email"}
              className="loginInput"
            />
          </div>
          <div className="inputWrapper">
            <label className="loginLabel">Password</label>
            <input
              onChange={handleChange}
              placeholder="secretkey123"
              name="password"
              type={"password"}
              className="loginInput"
            />
          </div>
          <button type="submit" className="loginButton">
            Login
          </button>
        </form>
      </div>
    </>
  );
};

export default Login;
