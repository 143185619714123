import React, { useState, useRef, useEffect, useCallback } from "react";
import "./offplan.css";
import CategoryCard from "../../Components/CategoryCard/CategoryCard";
import Footer from "../../Components/Footer/Footer";
import axios from "axios";
import { useParams } from "react-router";

const Offplan = () => {
  let params = useParams();
  let id = params?.id;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setData] = useState([]);
  const [property, setProperty] = useState([]);
  const carouselRef = useRef(null);

  useEffect(() => {
    const getdet = async () => {
      try {
        const response = await axios.get(
          `https://backend.aim-estate.com/api/offplan/${id}`
        );
        setProperty(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    getdet();
  }, [id]);
  useEffect(() => {
    const getItem = async () => {
      try {
        const response = await axios.get(
          `https://backend.aim-estate.com/api/suboffplan/sub/${id}`
        );
        setData(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    getItem();
  }, [id]);

  const scrollCarousel = useCallback(() => {
    const slideWidth = carouselRef.current.offsetWidth;
    carouselRef.current.scrollLeft = currentIndex * slideWidth;
  }, [currentIndex]);

  useEffect(() => {
    scrollCarousel();
  }, [scrollCarousel]);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % property.image.length);
  };

  const goToPrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? property.image.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="offplan_wrapper">
      <div className="off_plan_container">
        <div className="carousel_images_wrapp">
          <div className="carousel-con">
            <div className="carousel_off" ref={carouselRef}>
              {property.image &&
                property.image.map((image, index) => (
                  <div className="carousel-slide_off" key={index}>
                    <img src={image.url} alt={`Details ${index}`} />
                  </div>
                ))}
            </div>
            <button className="prev-button" onClick={goToPrev}>
              {/* <LeftIcon /> */}
              <i className="fa-solid fa-chevron-left"></i>
            </button>
            <button className="next-button" onClick={goToNext}>
              <i className="fa-solid fa-chevron-right"></i>

              {/* <RightIcon /> */}
            </button>
          </div>
        </div>
        <div className="description_offplan">
          <div>
            <h1>{property.name}</h1>
            <h3>{property.category}</h3>
            <p dangerouslySetInnerHTML={{ __html: property.description }}></p>
          </div>
        </div>
        <div className="offplan_features">
          <div className="feature f_location">
            <h3>Address</h3>
            <span>{property.address}</span>
            <i className="fa-solid fa-location-crosshairs"></i>
          </div>
          <div className="feature f_availability av">
            <h3>Expected Availability</h3>
            <span>{property.availability_expected}</span>
            <i className="fa-regular fa-clock"></i>
          </div>

          <div className="feature f_availability loc">
            <h3>Location</h3>
            <button
              type="button"
              className="buttonag rb_btn locbut"
              onClick={() => {
                window.location.href = property.location;
              }}
            >
              Check the map
            </button>
            <i className="fa-solid fa-map-location-dot"></i>
          </div>
        </div>

        <div className="offplan_properties">
          <h1 className="proptitle">Properties</h1>
          <div
            className="properties_content"
            style={{ transition: "opacity 1s linear" }}
          >
            {data ? (
              data.map((card) => (
                <div key={card._id}>
                  <CategoryCard
                    price1={card.price_low}
                    name={card.name}
                    price2={card.price_high}
                    bedroom={card.bedrooms}
                    bathroom={card.bathrooms}
                    size={card.size}
                    id={card._id}
                    type={card.property_type}
                    image={card.image[0]}
                  />
                </div>
              ))
            ) : (
              <div className="loader-container">
                <div className="cube">
                  <div className="face front"></div>
                  <div className="face back"></div>
                  <div className="face right"></div>
                  <div className="face left"></div>
                  <div className="face top"></div>
                  <div className="face bottom"></div>
                </div>
                <p className="first_loading_p">Loading...</p>
                <p>Please wait</p>
              </div>
            )}
            <div className="divider_spacer"></div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Offplan;
