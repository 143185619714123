import { Route, Routes } from "react-router-dom";
import AdminLayout from "./Layouts/AdminLayout";
import Login from "./Pages/Login/Login";
import UserLayout from "./Layouts/UserLayout";
import Home from "./Pages/Home";
import CategoriesLayout from "./Layouts/CategoriesLayout";
import { ToastContainer } from "react-toastify";
import RequireAuth from "./Hooks/RequireAuth";
import UsersPage from "./Components/Dashboard/Users/usersPage";
import Buypage from "./Components/Dashboard/Buy/BuyPage";
import LeadForm from "./Components/Leadform/LeadForm";
import NotFound from "./Pages/404/NotFound";
import Contactus from "./Components/ContactUs/Contactus";
import Aboutus from "./Pages/Aboutus/Aboutus";
import RentPage from "./Pages/Properties/Rent/RentPage";
import OffPlanPage from "./Pages/Properties/OffPlan/OffPlanPage";
import BuyPage from "./Pages/Properties/Buy/BuyPage";
import Singlepage from "./Pages/Singlepage/singlepage";
import Offplan from "./Pages/Offplan/Offplan";
import OffPlanpage from "./Components/Dashboard/OffPlan/OffPlanPage";
import Rentpage from "./Components/Dashboard/Rent/RentPage";
import Subpage from "./Components/Dashboard/SubOffPlan/SubPage";
import PrivacyPolicy from "./Pages/privacyPolicy/PrivacyPolicy";
function App() {
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<RequireAuth />}>
          <Route path="/dashboard" element={<AdminLayout />}>
            <Route path="/dashboard/users" element={<UsersPage />} />
            <Route path="/dashboard/buy" element={<Buypage />} />
            <Route path="/dashboard/rent" element={<Rentpage />} />
            <Route path="/dashboard/offplan" element={<OffPlanpage />} />
            <Route path="/dashboard/suboffplan" element={<Subpage />} />
          </Route>
        </Route>
      </Routes>
      <Routes>
        <Route element={<UserLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contactus />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/inquiry" element={<LeadForm />} />
          <Route path="/property/:type/:id" element={<Singlepage />} />
          <Route path="/offplan/:id" element={<Offplan />} />
          <Route path="/offplanpage" element={<OffPlanPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Route>
        <Route element={<CategoriesLayout />}>
          <Route path="/buy" element={<BuyPage />} />
          <Route path="/rent" element={<RentPage />} />
        </Route>
        <Route path="/error" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
