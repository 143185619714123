import React, { useState } from 'react';
import Logo from '../../Assets/logo1.png';
import './navbar.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const NavBar = () => {
  const [click, setCLick] = useState(false);
  const [color, setColor] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleInq = () => {
    window.location.href = '/contact';
    setCLick(false);
  };
  function changeColor() {
    const viewportWidth = window.innerWidth;
    let scrollPosition;

    if (viewportWidth >= 1300) {
      scrollPosition = 830;
    } else if (viewportWidth <= 1300 && viewportWidth > 850) {
      scrollPosition = 680;
    } else if (viewportWidth <= 850 && viewportWidth > 480) {
      scrollPosition = 750;
    } else if (viewportWidth <= 480) {
      scrollPosition = 580;
    }

    if (window.scrollY >= scrollPosition) {
      setColor(true);
    } else {
      setColor(false);
    }
  }

  window.addEventListener('scroll', changeColor);

  return (
    <div className={location.pathname !== '/' ? 'graynav shanty' : 'shanty'}>
      <header className={color ? 'bg_nav' : null}>
        <Link to="/"><img src={Logo} alt='Logo' className='nav_logo' /></Link>
        <ul className={click ? 'menu_left' : null}>
          <ul className='ul_lists_wrapper'>
            <li>
              <Link
                to='/'
                onClick={() => {
                  setCLick(false);
                }}
              >
                <div className='menu-container'>
                  {click && <i className='fa fa-home'></i>}
                  <p>Home</p>
                </div>
              </Link>
            </li>
            <li className='drp_nav' tabIndex={0}>
              <div className='menu-container'>
                {click && <i className='fa-regular fa-building'></i>}
                <p>Properties</p>
              </div>
              <div className='drp_nav_list'>
                <Link
                  to='/buy'
                  onClick={() => {
                    setCLick(false);
                  }}
                  tabIndex={0}
                >
                  Buy
                </Link>
                <Link
                  to='/rent'
                  onClick={() => {
                    setCLick(false);
                  }}
                  tabIndex={0}
                >
                  Rent
                </Link>
                <Link
                  to='/offplanpage'
                  onClick={() => {
                    setCLick(false);
                  }}
                  tabIndex={0}
                >
                  Offplans
                </Link>
                <div className='for_bfr'></div>
              </div>
            </li>
            <li>
              <Link
                to='/aboutus'
                onClick={() => {
                  setCLick(false);
                }}
              >
                <div className='menu-container'>
                  {click && <i className='fa-solid fa-people-group'></i>}
                  <p>About us</p>
                </div>
              </Link>
            </li>
            <li>
              <Link onClick={handleInq}>
              <div className='menu-container'>
                  {click && <i className='fa-regular fa-address-book'></i>}
                  <p>Contact</p>
                </div>
              </Link>
            </li>
          </ul>
          <div className='nav_btns_wrapper'>
            <button onClick={handleInq}>Inquiry</button>
          </div>
        </ul>
        <div className='d-flex align-center mt-10'>
          {click && <img src={Logo} alt='Logo' className='mob-logo' />}
          <div
            className='brgr_icn'
            onClick={() => {
              setCLick(!click);
            }}
          >
            {click ? (
              <i className='fa-solid fa-xmark'></i>
            ) : (
              <i className='fa-solid fa-bars'></i>
            )}
          </div>
        </div>
      </header>
    </div>
  );
};

export default NavBar;
