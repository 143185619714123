import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './testimonials.css';

const testimonials = [
  {
    id: 1,
    name: 'Ahmed S., Dubai, UAE',
    text: `Working with Omer and the AIM Real Estate team was a game-changer for me. They guided me through the entire process of buying an off-plan property in Dubai with such professionalism and expertise. The level of service and attention to detail was outstanding. I couldn't be happier with my investment!`,
    image:
      'testimonials2',
  },
  {
    id: 2,
    name: 'Fatima A., Ras Al Khaimah, UAE',
    text: `Omer and his team at AIM Real Estate made the process of purchasing an off-plan property in Ras Al Khaimah seamless and stress-free. Their knowledge of the market and dedication to their clients are truly unmatched. I felt supported every step of the way and am thrilled with my new property.`,
    image:
      'testimonials1',
  },
  {
    id: 3,
    name: 'Salman R., Dubai, UAE',
    text: `I had an amazing experience with AIM Real Estate when I decided to invest in an off-plan property in Dubai. Omer’s team went above and beyond to ensure that all my questions were answered and that I was confident in my decision. Their professionalism and commitment to client satisfaction are second to none.`,
    image:
      'testimonials3',
  },
  {
    id: 4,
    name: 'Mariam H., Ras Al Khaimah, UAE',
    text: `Buying an off-plan property in Ras Al Khaimah with AIM Real Estate was one of the best decisions I've made. Omer and his team provided exceptional service, guiding me through every step with patience and expertise. I highly recommend them to anyone looking to invest in the UAE real estate market.`,
    image:
      'testimonials4',
  },
  {
    id: 5,
    name: 'Yousef K., Dubai, UAE',
    text: `Omer and the AIM Real Estate team were incredible to work with. They made the process of buying an off-plan property in Dubai so easy and enjoyable. Their deep knowledge of the market and unwavering support were invaluable. I’m absolutely delighted with my new home!`,
    image:
      'testimonials5',
  },
  // Add more testimonials as needed
];

const Testimonials = () => {
  const [index, setIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    let interval;
    if (!isPaused) {
      interval = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
      }, 4000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isPaused, index]);

  const handleMouseEnter = () => setIsPaused(true);
  const handleMouseLeave = () => setIsPaused(false);

  return (
    <div className='testinomial-main'>
      <h2>What Our Clients Say</h2>
      <Carousel
        activeIndex={index}
        onSelect={(selectedIndex) => setIndex(selectedIndex)}
        className='testimonial-carousel'
      >
        {testimonials.map((testimonial) => (
          <Carousel.Item key={testimonial.id}>
            <div className='testimonial-item d-flex flex-column align-items-center text-center'>
              <img
                className='testimonial-image'
                src={process.env.PUBLIC_URL + `/${testimonial.image}.png`}
                alt={testimonial.name}
              />
              <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <h5>{testimonial.name}</h5>
                <p>{testimonial.text}</p>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default Testimonials;
