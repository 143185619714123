import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import dltIcon from "../../../Assets/deleteIcon.svg";
import { Spinner } from "react-bootstrap";
import * as formik from "formik";
import * as Yup from "yup";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactQuill from "react-quill";
import { useState,useEffect } from "react";

const OffPlanForm = ({
  show,
  onHide,
  property,
  createProperty,
  setProperty,
  images,
  setImages,
  edit,
  deleteImage,
  setEdit,
  loading,
  setLoading
}) => {
  const [description, setDescription] = useState(property.description)
  useEffect(() => {
   setProperty({...property,
  description:description})
  }, [description])

  const renderExistingImages = () => {
    return (

      <div className="images-parent">
        {edit === true &&
          images.map((image, i) => (
            <div key={i} className="edit-image-container">
              <img
                src={dltIcon}
                onClick={() => deleteImage(image.public_id, property._id)}
                alt="delete"
                
                className="image-dlt-icon"
              />
              <img
                className="edit-form-image"
                src={image.url}
                alt="random"
                
              />
            </div>
          ))}
      </div>
    );
  };
  const { Formik } = formik;
  const schema = Yup.object().shape({
    name: Yup.string().required().min(6),
    category: Yup.string().required(),
    address:Yup.string().required(),
    availability_expected:Yup.string().required(),
    location:Yup.string().required()

  });
  let categoryOptions=["Residential","Commercial"]
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setProperty({
      ...property,
      [name]: value,
    });
  };
  const handleImageChange = (e) => {
    const selectedImages = Array.from(e.target.files);
    setImages(selectedImages);
  };
  useEffect(() => {
    setDescription(property.description)
  }, [property.description])


  return (
    <>
      <Modal className="modal-open" show={show} onHide={onHide} centered>
        <Formik
          validationSchema={schema}
          initialValues={property}
          onSubmit={(values) => {
            if (
              values.name &&
              values.category&&
              values.address&&
              values.location&&
              values.availability_expected
            ) {
              setLoading(true)
              createProperty();
            }
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form onSubmit={handleSubmit} noValidate>
              <Modal.Header>
                <Modal.Title>Add Property</Modal.Title>
              </Modal.Header>
              <Modal.Body
                className="modal-body"
                style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}
              >
                {edit === true ? renderExistingImages() : ""}
                <Form.Group controlId="validationFormik01">
                  <Form.Label>Images</Form.Label>
                  <Form.Control
                    className="form-control"
                    type="file"
                    onChange={handleImageChange}
                    accept=".png, .jpg, .jpeg"
                    multiple
                  />
                </Form.Group>
                <Form.Group controlId="validationFormik02">
                  <Form.Label>Property name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={(event) => {
                      handleChange(event);
                      handleInputChange(event);
                    }}
                    isValid={touched.name && !errors.name}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="validationFormik04">
                <Form.Label> Category</Form.Label>
                <Form.Select
                name="category"
                value={values.category}
                onChange={(event) => {
                  handleChange(event);
                  handleInputChange(event);
                }}
                isValid={
                  touched.category && !errors.category
                }
                isInvalid={!!errors.category}
              >
                <option>
                  {property.category || "Choose Category"}
                </option>
                {categoryOptions.map((option, i) => (
                  <option key={i} name="category" value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.category}
                </Form.Control.Feedback>
              </Form.Group>
                <Form.Group controlId="validationFormik02">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  value={values.address}
                  onChange={(event) => {
                    handleChange(event);
                    handleInputChange(event);
                  }}
                  isValid={touched.address && !errors.address}
                  isInvalid={!!errors.address}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.address}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationFormik02">
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                name="location"
                value={values.location}
                onChange={(event) => {
                  handleChange(event);
                  handleInputChange(event);
                }}
                isValid={touched.location && !errors.location}
                isInvalid={!!errors.location}
              />
              <Form.Control.Feedback type="invalid">
                {errors.location}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationFormik02">
            <Form.Label>Expected availibility</Form.Label>
            <Form.Control
              type="text"
              name="availability_expected"
              value={values.availability_expected}
              onChange={(event) => {
                handleChange(event);
                handleInputChange(event);
              }}
              isValid={touched.availability_expected && !errors.availability_expected}
              isInvalid={!!errors.availability_expected}
            />
            <Form.Control.Feedback type="invalid">
              {errors.location}
            </Form.Control.Feedback>
          </Form.Group>
            
                 
               
          <Form.Group controlId="validationFormik13">
          <Form.Label>Description</Form.Label>
          <ReactQuill
            value={description}
            onChange={setDescription}
            theme="snow"
          />
        </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                  Cancel
                </Button>
                {loading && <Spinner animation="border" role="status" />}

                <Button type="submit" >add</Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default OffPlanForm;
